<template lang="pug">
    .login-page#page-login.primary
        v-container
          v-form(ref="form", v-model="form" id="form" @submit.prevent="handleSignIn")
              v-row(dense)
                v-col(cols="12" md="6" offset-md="3")
                  v-card(color="white" max-width="500px").pa-2.pa-md-5.mx-auto
                    v-expand-transition
                      .loading(v-if="loading")
                        h2.display-1.my-12 Signing in...
                        v-skeleton-loader(type="card")
                      .ready(v-else)
                        v-img(src="@/assets/logo_dark_horizontal.svg" :width="image.width", min-height="100px" min-width="200px" max-width="60vw" contain).mb-5.mx-auto
                        //- pre user = {{userData}}
                        //- pre company = {{company}}
                        //- pre origin = {{origin}}

                        v-text-field#email(v-model="email", label="Your email", type="email", placeholder="*Email address", outlined, :rules="[rules.required, rules.email]")
                        v-text-field#password(v-model="password", label="Password" placeholder="*Password", hint="Register first, if you don't know your password.", outlined, :rules="[rules.required, rules.password]", :type="showPasswordInput ? 'text' : 'password'", :append-icon="showPasswordInput ? 'mdi-eye' : 'mdi-eye-off'", @click:append="() => (showPasswordInput = !showPasswordInput)", @input="errorMessage = false")
                        v-btn#btn-sign-in(type="submit" block dark color="primary" depressed x-large @click="handleSignIn").white--text.font-weight-bold.mb-3 Sign In Now
                        v-banner#sign-in-error.mt-2.error--text.text-center(form="form" v-if="errorMessage") {{errorMessage}}

                        .text-center
                          small Don't have a password? You can <router-link to="/register">register to receive it</router-link>.

</template>

<script>
import { isValidEmail, isValidPassword } from '@/helpers'
import { sync, call } from 'vuex-pathify'
export default {
  name: 'Login',
  components: {},
  data() {
    return {
      image: {
        width: this.$vuetify.breakpoint.smAndDown ? '125px' : '175px',
        height: this.$vuetify.breakpoint.smAndDown ? '125px' : '175px'
      },
      // email: 'james.anderson@example.com',
      // password: 'abc123abc',
      email: '',
      password: '',
      showPasswordInput: false,
      loading: false,
      errorMessage: false,
      form: null,
      origin: null,
      rules: {
        required: value => !!value || 'Required.',
        password: value => { return isValidPassword(value) || 'Your password should contain a number and be at least 8 characters long' },
        email: value => {
          return isValidEmail(value) || 'Please enter your best e-mail.'
        }
      }
    }
  },
  mounted() {
    const { email, originPath } = this.$route.query
    this.originPath = originPath
    if (email) {
      this.email = email
    }
  },
  computed: {
    ...sync({ userData: 'user/userData', company: 'user/company' })
  },
  methods: {
    ...call({
      signIn: 'user/signIn'
    }),

    async handleSignIn() {
      try {
        this.loading = true
        if (this.form) {
          const signInRes = await this.signIn({ email: this.email.trim(), password: this.password.trim() })
          // console.log('signInRes', signInRes)

          if (signInRes) {
            if (this.originPath) {
              console.log('going to originPath', this.originPath)
              this.$router.push(this.originPath)
            } else {
              await this.$router.push('/inbox/action/submit')
            }
          }
        } else {
          console.log('Nope, validating form...')
          this.$refs.form.validate()
        }
      } catch (err) {
        console.error(err)
        this.errorMessage = err
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/css/colors.scss";
.login-page {
  min-height:100vh;
}
</style>
