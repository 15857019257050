<template lang="pug">
    .sign-up-page#page-sign-up.primary
      template(v-if="finished")
        .max-width.py-md-12
          v-card(color="white" max-width="600px").pa-5.mx-auto
            v-img(src="@/assets/logo_dark_horizontal.svg" :width="image.width", min-height="200px" min-width="250px" max-width="60vw" contain).mx-auto

            h1.mb-3.text-center {{text.successText.title}} {{firstName}}!
            p(v-for="p in text.successText.paragraphs", :key="p", v-html="p")
            .text-center
              v-btn(color="primary darken-1", x-large, depressed :to="'/login?email=' + this.email" block).mx-auto
                | Log In Now
                v-icon(color="white").ml-2 mdi-arrow-right

      template(v-else)
        .max-width.d-md-flex.flex-column.align-center.py-md-12

            v-row(align="stretch").d-inline.d-md-flex
              v-col(cols="12", md="5").d-md-flex.flex-column.grey.lighten-4
                .d-md-flex.flex-grow-1.align-center
                  .transparent
                    .text-center.ma-8.mt-0(:class="{'ma-md-4': lang === 'ko'}")
                      h1 {{text.title}}
                    .text-left.px-2.px-md-6
                      .subheader.text-dark.text-center.mb-8(v-if="lang === 'ko'") – 누릴 수 있는 혜택 –
                      ol.text-dark
                        li(v-for="feature in text.features", :key="feature" v-html="feature")

              v-col(cols="12", md="7").white.flex-grow-1
                v-form(ref="form", v-model="form"  @submit="handleSignUp")
                  .pa-2.pa-md-5.ma-0
                    v-expand-transition
                      .loading(v-if="loading")
                        h2.display-1.my-12 Signing you up now...
                        v-skeleton-loader(type="card")
                      .ready(v-else)
                        //- pre user = {{userData}}
                        //- pre company = {{company}}
                        v-img(src="@/assets/logo_dark_horizontal.svg" :width="image.width", min-height="200px" min-width="250px" max-width="60vw" contain).mx-auto

                        v-text-field#company(v-model="company", :label="text.placeholders.company", :placeholder="text.placeholders.company", outlined, :rules="[rules.required]",)
                        v-text-field#firstName(v-model="firstName", :label="text.placeholders.name", :placeholder="text.placeholders.name", outlined, :rules="[rules.required]",)
                        v-text-field#email(v-model="email", :label="text.placeholders.email", type="email", :placeholder="text.placeholders.email", outlined, :rules="[rules.required, rules.email]")
                        v-text-field#password(v-model="password", :label="text.placeholders.password" :placeholder="text.placeholders.password", hint="Your password should contain a number and be at least 8 characters long.", outlined, :rules="[rules.required, rules.password]", :type="showPasswordInput ? 'text' : 'password'", :append-icon="showPasswordInput ? 'mdi-eye' : 'mdi-eye-off'", @click:append="() => (showPasswordInput = !showPasswordInput)", @input="notFound = false")

                        v-banner#sign-in-error.mb-2.error--text.text-center(v-if="notFound") Ack! We couldn't find that company. Are you sure you got the company code right?
                        v-banner#sign-in-error.mb-2.error--text.text-center(v-if="registrationError") Ack! We couldn't register you in the system for some reason.

                        v-btn#btn-sign-in(block dark color="primary" depressed x-large @click="handleSignUp").white--text.font-weight-bold.mb-3 {{text.buttonText}}
                        .text-center.mt-3
                          small(v-html="text.loginOffer")

</template>

<script>
import { isValidEmail, isValidPassword } from '@/helpers'
import { get, call } from 'vuex-pathify'
export default {
  name: 'Login',
  components: {},
  data() {
    return {
      image: {
        width: this.$vuetify.breakpoint.smAndDown ? '125px' : '175px',
        height: this.$vuetify.breakpoint.smAndDown ? '125px' : '175px'
      },
      showPasswordInput: false,
      loading: false,
      notFound: false,
      registrationError: false,
      form: null,
      // company: 'Apex IT',
      // email: '',
      // firstName: 'Dima',
      // password: 'abc123abc',
      company: '',
      email: '',
      firstName: '',
      password: '',
      userId: '',
      finished: false,
      rules: {
        required: value => !!value || 'Required.',
        password: value => {
          return (
            isValidPassword(value) ||
            'Your password should contain a number and be at least 8 characters long'
          )
        },
        email: value => {
          return isValidEmail(value) || 'Please enter your best e-mail.'
        }
      },
      textVersions: {
        en: {
          title: 'Upgrade your communication with FREE native proofreading',
          features: [
            'You can start using PROIT English for <b>FREE, with no credit card required.</b>',
            'Use your free trial credits to submit an email or marketing text for proofreading.',
            'Need a hand? You can email us at <a class="black--text" href="mailto:support@proitenglish.com">support@ProItEnglish.com</a>'
          ],
          placeholders: {
            company: '*Company Name',
            name: '*First Name',
            email: '*Email address',
            password: '*Password'
          },
          successText: {
            title: 'Awesome',
            paragraphs: [
              'To get you started, we have created your first team user. You can login now.',
              'When logging in, the username will be your email address and the password is the one you just created.'
            ]
          },

          buttonText: 'Sign Up',
          loginOffer: `Already registered? You can <a class="text-none" href="/login?email=${this
            .email || ''}">Sign In</a>.`
        },
        ru: {
          title: 'Улучшите свое общение с помощью бесплатной редакции текста',
          features: [
            'Вы можете начать использовать PROIT English <b>БЕСПЛАТНО, без кредитной карты</b>.',
            'Используйте свои бесплатные пробные кредиты для того, чтобы отправить электронное письмо или рекламный текст для редактирования.',
            'Нужна помощь? Вы можете написать нам по адресу <a class="black--text" href="mailto:support@proitenglish.com">support@ProItEnglish.com</a>'
          ],
          placeholders: {
            company: '*Компания',
            name: '*Имя',
            email: '*Эл. адрес',
            password: '*Пароль'
          },
          successText: {
            title: 'Отлично!',
            paragraphs: [
              'To get you started, we have created your first team user. You can login now.',
              'When logging in, the username will be your email address and the password is the one you just created.'
            ]
          },

          buttonText: 'Sign Up',
          loginOffer: `Уже зарегистрированы? Вы можете <a class="text-none" href="/login?email=${this
            .email || ''}">войти в систему</a>.`
        },
        ko: {
          title:
            '영어문장 첨삭을 위해 네이티브의 무료 영어교정서비스를  이용해보세요 ! ',
          features: [
            '<b>신용카드 없이도</b> PROIT English를 무료로 사용하실 수 있습니다.',
            '오직 가입만으로 <b>5개의 영어문장 무료 첨삭권을</b> 이용하실 수 있습니다. 가입하셔서 이 엄청난 혜택을 누리세요.',
            '질문이 있으시면, <a class="text-none" href="mailto:support@ProItEnglish.com">support@ProItEnglish.com</a> 로 궁금한 사항을 보내주세요. 확인 후 바로 답변드리겠습니다.'
          ],
          placeholders: {
            company: '*회사 이름',
            name: '*이름',
            email: '*이메일',
            password: '*비밀번호'
          },
          successText: {
            title: '가입이 완료되었습니다!',
            paragraphs: [
              '이제 해당 프로그램을 이용하시려면 로그인 해주세요.',
              '가입시 기입하셨던 이메일과 비밀번호로 로그인 하실 수 있습니다.'
            ]
          },
          buttonText: '시작하기',
          loginOffer: `이미 등록하셨습니까? <a class="text-none" href="/login?email=${this
            .email || ''}">로그인</a> 할 수 있습니다.`
        }
      }
    }
  },
  mounted() {
    const { company, firstName, email } = this.$route.query
    this.origin = origin
    if (company) this.company = company
    if (firstName) this.firstName = firstName
    if (email) this.email = email
  },
  computed: {
    ...get({ userData: 'user/userData', lang: 'user/lang' }),
    text() {
      return this.textVersions[this.lang]
        ? this.textVersions[this.lang]
        : this.textVersions.en
    }
  },
  methods: {
    ...call({
      signUp: 'user/signUp'
    }),

    async handleSignUp() {
      try {
        this.loading = true
        if (this.form) {
          await this.signUp({
            company: this.company,
            firstName: this.firstName,
            email: this.email,
            password: this.password
          })

          this.finished = true
        } else {
          console.log('Nope, validating form...')
          this.$refs.form.validate()
        }
      } catch (err) {
        if (err.toString().includes('COULD_NOT_CREATE_FIREBASE_USER')) {
          this.registrationError = true
        } else {
          this.notFound = true
        }
        console.error(err)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/css/colors.scss";
.sign-up-page {
  h1 {
    font-size: 2rem;
  }

  h1,
  h2,
  h3,
  p,
  span,
  li,
  div {
    word-break: keep-all;
  }

  min-height: 100vh;

  .max-width {
    max-width: 1200px;
    margin: auto;
  }

  .min-height {
    min-height: 100vh;
  }

  ol li {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
}
</style>
